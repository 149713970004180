.slider-container {
    position: relative;
    width: 100%;
    margin-top: 20px;
  }
  
  .slider {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .prev-image,
  .next-image,
  .current-image {
    width: 200px;
    height: 200px;
    overflow: hidden;
    position: relative;
    margin: 0 5px; /* Thay đổi khoảng cách giữa các hình ảnh */

  }
  
  .prev-image img,
  .next-image img,
  .current-image img {
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease;
  }
  
  .prev-image img,
  .next-image img {
    opacity: 0.5;
  }
  
  /* .current-image img {
    opacity: 1;
  }
  
  .prev-image img {
    transform: translateX(-50%);
  }
  
  .next-image img {
    transform: translateX(50%);
  } */
  .prev-image,
  .next-image,
  .current-image {
    width: 200px;
    height: 200px;
    overflow: hidden;
    position: relative;
    margin: 0 2px; /* Giảm khoảng cách giữa các ảnh */
  }

  
  /* .big-image {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    height: 1000px;
  } */
  .big-image {
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }
  
  .big-image img {
    max-width: 100%; /* Đảm bảo rằng ảnh không vượt quá chiều rộng của phần tử cha */
    height: 750px; /* Đặt chiều cao tối đa cho ảnh */
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }
  