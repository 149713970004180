/*TabsSection.css*/
.tabs{
  display:flex;
  width: 100%;
  height: 100px;
}
.tablinks {
  /* color: #ffffff; */
  border: none;
  outline: none;
  cursor: pointer;
  width: 80%;
  padding: 1rem;
  font-size: 20px;
  /* text-transform: uppercase; */
  font-weight:300;
  transition: 0.1s ease;
  background:#ffffff;
}
.tablinks:hover{
  background:#ffffff;
  color:#03346d;
  font-weight: bold;
}
/* Tab active */
.tablinks.active {
  background:#ffffff;
  color:#03346d;
  font-weight: bold;
}

/* tab content */
.tabcontent {
  display: none;
  width: auto;
  margin:0px;
}
/* Text*/
.tabcontent p {
  color: #333;
  font-size: 16px;
}
/* tab content active */
.tabcontent.active {
  display: block;
  width: 100%;
}

#tabsHome {
  width: 300%;
  font-size: 30px;
  text-align: left;
  color: navy;
  font-weight: bold;
}

.dropdown-content {
  width: auto; /* Adjust as needed, or remove to let it automatically adjust based on content */
}

.dropdown-menu .tablinks {
  width: 200%; /* Định dạng lại chiều rộng cho tab giới thiệu trong Dropdown */
}

.tablinks-dropdown {
  /* Thêm các thuộc tính CSS riêng cho tab giới thiệu trong Dropdown */
  width: 130px; /* hoặc bất kỳ giá trị nào bạn muốn */
  height: 100%;
  font-size: 20px;
  font-weight: 300;
  background: #ffffff;
  border: none;
  outline: none;
}

.tablinks-dropdown:hover {
  background: #ffffff;
  color: #03346d;
  font-weight: bold;
}

.tablinks-dropdown.active {
  background: #ffffff;
  color: #03346d;
}