/* CamNang.css */

#body {
    margin: 20px;
  }
  
  .divBlog {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff; /* Thêm nền trắng */
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .divBlog h3 {
    color: #333;
  }
  
  .divBlog p {
    color: #666;
    line-height: 1.6;
  }
  
  .divBlog img {
    max-width: 100%;
    height: auto;
    margin-right: 20px;
  }
  