#body h2 {
  font-size: 24px; /* Tăng kích thước của tiêu đề */
  font-weight: bold;
  margin-bottom: 20px;
  color: #000000; /* Màu chữ đậm */
  text-align: center; /* Căn giữa văn bản */
  transition: color 0.1s cubic-bezier(0.25, 0.1, 0.25, 1); /* Thêm hiệu ứng chuyển đổi màu */
  
}


.blog-post {
  width: 50%;
  margin: auto;
}

.post-content {
  background-color: #71d4df;
  padding: 50px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.section img {
  width: 100%; /* Thay đổi từ max-width thành width */
  height: auto;
  margin-bottom: 10px;
}

.section strong {
  font-weight: bold;
}

.section ul {
  list-style-type: disc;
  margin-left: 20px;
}

.section ul li {
  margin-bottom: 5px;
}

.section p {
  margin-bottom: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
/* Ẩn đi các máy khi đã chọn một máy */
.dropdown.selected .dropdown-content {
  display: none;
}


#header1 a:hover {
  color: #20a84e; /* Màu sắc thay đổi khi di chuột qua */
}

#a1:hover {
  transform: scale(1.1); /* Phóng to phần tử khi di chuột qua */
}
.divBlog h3 {
  font-size: 20px;
  transition: transform 0.3s ease-in-out;
}

.divBlog:hover h3 {
  transform: scale(1.1);
}

.divBlog.special p:hover {
  color: #1652c6;
  border-bottom: 2px solid #1652c6;
  text-shadow: 0 0 10px rgba(234, 50, 50, 0.5);
}

.divBlog.special .highlight {
  color: #c61616;
  animation: bounce 1s infinite;
}

#tb1 p{
  font-weight: normal; 
  padding: 10px;
  text-align: left;
}

#ha{
  font-size: 30px;
}

@keyframes blink {
  0% { fill: #FFD700; }
  50% { fill: #FFFF00; }
  100% { fill: #FFD700; }
}

.blink {
  animation: blink 0.5s infinite;
}

#GioiThieuRes {
  width: 100%;
}