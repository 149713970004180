/* Common styles */
#body {
  padding: 50px;
  font-family: Arial, sans-serif;
}

#table1 tr, td {
  width: 50%;
  text-align: left;
  padding-right: 30px;
}

#dichvuText {
  font-size: 60px;
  text-align: left;
}

#table1 p {
  text-align: left;
  letter-spacing: 2px;
  font-size: 18px;
}

#table2 {
  width: 100%;
}

#table2 tr, td {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 55px;
  padding-right: 30px;
  vertical-align: top;
}

#table2 img {
  width: 360px;
  height: 360px;
}

#topic {
  font-size: 25px;
  padding-top: 15px;
}

.divBlog {
  background-color: blue;
  border: 10px solid #f00;
  padding: 20px;
  border-radius: 20px;
  font-family: Arial, sans-serif;
}

.divBlog h3,
.divBlog p {
  font-size: 20px;
}

/* Responsive styles */
@media only screen and (max-width: 767px) {
  #body {
    padding: 20px;
  }
  
  #table1 tr, td {
    width: 100%;
  }
  
  #table1 p {
    font-size: 16px;
  }
  
  #table2 tr, td {
    display: block;
    width: 100%;
    padding-bottom: 20px;
  }
  
  #table2 img {
    width: 100%;
    height: auto;
  }
  
  #topic {
    font-size: 20px;
  }
  
  .divBlog {
    padding: 15px;
  }
  
  .divBlog h3 {
    font-size: 18px;
  }
  
  .divBlog p {
    font-size: 14px;
  }
}





#bgblack .divBlog {
    background-color: blue;
    border: 10px solid #f00; /* Khung nổi bật */
    padding: 20px; /* Tăng kích thước padding */
    border-radius: 20px; /* Đường viền cong */
    animation: highlightBorder 1s infinite alternate; /* Hiệu ứng nhấp nháy cho khung */
    font-family: Arial, sans-serif; /* Phông chữ */
}

#bgblack .divBlog h3,
#bgblack .divBlog p {
    animation: changeColor 2s infinite alternate; /* Hiệu ứng thay đổi màu chữ */
    font-size: 20px; /* Kích thước phông chữ */
}

@keyframes highlightBorder {
    0% {
      border-color: #f00; /* Màu khung ban đầu */
    }
    100% {
      border-color: #ff0; /* Màu khung mới */
    }
}

@keyframes changeColor {
    0% {
      color: #f00; /* Màu chữ ban đầu */
    }
    25% {
      color: #0f0; /* Màu chữ mới */
    }
    50% {
      color: rgb(0, 217, 255); /* Màu chữ mới */
    }
    75% {
      color: #ff0; /* Màu chữ mới */
    }
    100% {
      color: #f00; /* Trở lại màu ban đầu */
    }
}
